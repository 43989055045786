var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"商品配送详情","center":"","close-on-click-modal":false,"top":"100px","visible":_vm.visible,"width":"1150px"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('vxe-table',{ref:"table",attrs:{"border":"","highlight-hover-row":"","header-align":"center","resizable":"","start-index":(_vm.tablePage.pageNo - 1) * _vm.tablePage.pageSize,"show-header":true,"data":_vm.tableData}},[_c('vxe-table-column',{attrs:{"field":"phone","align":"center","width":"130","title":"手机号"}}),_c('vxe-table-column',{attrs:{"field":"desk_name","align":"center","width":"100","title":"球桌"}}),_c('vxe-table-column',{attrs:{"field":"is_source","align":"center","title":"支付方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_source===1)?_c('div',[_vm._v("收银台")]):_vm._e(),(row.is_source===2)?_c('div',[_vm._v("收银商城")]):_vm._e()]}}])}),_c('vxe-table-column',{attrs:{"field":"list","align":"center","width":"200","title":"商品信息"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.list),function(item,index){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(index+1)+". "+_vm._s(item.name)+" x "+_vm._s(item.num)+" ￥"+_vm._s(item.actual_price)+" ")])})}}])}),_c('vxe-table-column',{attrs:{"field":"total_price","align":"center","width":"110","title":"总价（元）"}}),_c('vxe-table-column',{attrs:{"field":"created_at","align":"center","width":"140","title":"提交时间"}}),_c('vxe-table-column',{attrs:{"field":"is_delivery","align":"center","title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_delivery===0)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("待配送")]):_vm._e(),(row.is_delivery===1)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("已配送")]):_vm._e()]}}])}),_c('vxe-table-column',{attrs:{"align":"center","title":"操作","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_delivery==0)?_c('el-button',{staticStyle:{"background-color":"#00ca64","color":"#FFFFFF"},attrs:{"size":"small"},on:{"click":function($event){return _vm.onDispatch(row)}}},[_vm._v("配送")]):_c('el-tag',{staticStyle:{"margin-right":"20px"},attrs:{"type":"info"}},[_vm._v("已配送")]),_c('el-button',{staticStyle:{"background-color":"#00ca64","color":"#FFFFFF"},attrs:{"size":"small"},on:{"click":function($event){return _vm.onDelete(row)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"custom-dialog-table"},[_c('vxe-pager',{attrs:{"background":"","current-page":_vm.tablePage.pageNo,"page-size":_vm.tablePage.pageSize,"total":_vm.tablePage.totalCount,"layouts":['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'Total']},on:{"page-change":_vm.handlePageChange}})],1),(_vm.showAllDispatch())?_c('el-button',{staticClass:"all-dispatch-button",attrs:{"slot":"footer"},on:{"click":_vm.onAllDispatch},slot:"footer"},[_vm._v("全部配送")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }

import apiClass from "@/common/api/business";
import {Component, Vue } from "vue-property-decorator";
import {Util} from "@/common/util";
@Component({})
export default class CancelMergePools extends Vue{
  private visible:boolean = false
  private apiClass: any = new apiClass();
  private tableData:any  = []
  private orderInfo:any = {}
  private tablePage:any =  {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  }
  private show(orderInfo:any):void {
    this.orderInfo = orderInfo
    this.getList()
  }
  private getList():void {
    const { order_id } = this.orderInfo
    this.apiClass.goodsSaleDeliveryList({
          order_id,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          console.log('商品待配送列表',res.data.list)
          this.tableData = res.data.list
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
          this.visible = true
        }
    );
  }

  private showAllDispatch(){
    const newData = this.tableData.filter((item:any)=>item.is_delivery===0)
    return newData.length>0
  }

  private onDispatch(row:any) {
    this.apiClass.goodsSaleDelivery({
      id:row.id
    },((res:any)=>{
      Util.showToast("配送成功");
      this.$emit('success')
      this.getList()
    }))
  }
  private onDelete(row:any) {
    this.apiClass.goodsSaleDeleteDelivery({
      id:row.id,
      desk_id:row.desk_id
    },((res:any)=>{
      Util.showToast("删除成功");
      this.$emit('success')
      this.getList()
    }))
  }



  private onAllDispatch() {
    const newData = this.tableData.filter((item:any)=>item.is_delivery===0)
    const ids = newData.map((item:any)=>item.id).toString()
    console.log('ids',ids)
    this.apiClass.goodsSaleAllDelivery({ids},((res:any)=>{
      Util.showToast("全部配送成功");
      this.$emit('success')
      this.getList()
    }))
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getList()
  }

}

